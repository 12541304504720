import * as React from "react";
import { useNavigate } from "react-router-dom";
import TableUsers from "./TableUsers/TableUsers";
// import {
//     getModalOpen,  setModalOpen,
//   } from "./siteSlice";
import Footer from "../../Footer/Footer";
import { useGetUserListQuery } from "../../api/apiSlice";
export default function LandingUsers() {
  let navigate = useNavigate();

  const handleGoToClients = (event) => {
    navigate("/clients/");
  };

  const
  {
  data: userData,
  isLoading: userLoading,
  isSuccess: userSuccess,
  isError: userError,
  error: userMessage,
} = useGetUserListQuery();

if (userLoading) {
  return (
      <div className="center-screen">
          {/* <LoadingSpinner /> */}
      </div>
  );
} else if (userError ) {
  return (
      <div className="center-screen">
          {/* <Error500 /> */}
      </div>
  );
} else {

  return (
    <>

   <TableUsers user_data={userData.data}/>
   <Footer/>
    </>
  );
}
}