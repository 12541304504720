import React from "react";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector, useDispatch } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import GroupInfo from "./GroupInfo";
import ClearIcon from "@mui/icons-material/Clear";

import TextField from "@mui/material/TextField";
import {
    logout,
    getClient,
    getManager,
} from "../../../Authentication/authSlice";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../../../Globals/styling/theme";
import { useEditUserMutation } from "../../../api/apiSlice";
import {
    showSuccess,
    showError,
} from "../../../Notifications/notificationsSlice";

export default function ButtonEditUser({ user }) {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const is_client = useSelector(getClient);
    const is_manager = useSelector(getManager);

    const [
        editUser,
        {
            data: editData,
            isSuccess: iseditUserSuccess,
            error: editUserError,
            isError: iseditUserError,
        },
    ] = useEditUserMutation();

    useEffect(() => {
        if (iseditUserSuccess) {
            let message = `${editData.success}`;
            dispatch(showSuccess(message));
            setOpen(false);
        }
        if (iseditUserError) {
            let message = `${editUserError.data.error}`;
            dispatch(showError(message));
        }
    }, [iseditUserSuccess, iseditUserError]);

    let temp = "";
    const handleGroupsEdit = (groups, group_name) => {
        const found = groups.find((group) => group.name === group_name);

        return found ? true : false;
    };

    const [first_name, setfirst_name] = useState("");
    const [open, setOpen] = useState(false);
    const [last_name, setlast_name] = useState("");
    const [crawl_po, setCrawlPO] = useState(false);
    const [maintenance, setMaintenance] = useState(false);
    const [mechanical_engineer, setMechanicalEngineer] = useState(false);
    const [team_member, setTeamMember] = useState(false);
    const [pamp_client, setPampClient] = useState(false);
    const [pamp_manager, setPampManager] = useState(false);
    const [view_only, setViewOnly] = useState(false);
    const [project_manager, setProjectManager] = useState(false);
    const [compliance_officer, setcompliance_officer] = useState(false);
    const [product_owner, setproduct_owner] = useState(false);
    const [preng, setpreng] = useState(false);
    const [internal_external, setInternalExternal] = useState("");
    const internal_external_choices = ["Internal", "External"];
    const [cell_number, setCell_number] = useState("");
    const [value, setValue] = useState("");

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setfirst_name(user.first_name);
        setlast_name(user.last_name);
        setCrawlPO(handleGroupsEdit(user.groups, "Crawl Po"));
        setMaintenance(handleGroupsEdit(user.groups, "Maintenance"));
        setMechanicalEngineer(
            handleGroupsEdit(user.groups, "Mechanical Engineer")
        );
        setTeamMember(handleGroupsEdit(user.groups, "Team Member"));
        setPampClient(handleGroupsEdit(user.groups, "Pamp Client"));
        setPampManager(handleGroupsEdit(user.groups, "Pamp Manager"));
        setViewOnly(handleGroupsEdit(user.groups, "View Only"));
        setProjectManager(handleGroupsEdit(user.groups, "Project Manager"));
        setcompliance_officer(
            handleGroupsEdit(user.groups, "Compliance Officer")
        );
        setproduct_owner(handleGroupsEdit(user.groups, "Product Owner"));
        setpreng(handleGroupsEdit(user.groups, "Professional Engineer"));
        setInternalExternal(user.internal_external[0].internal_external);
        setCell_number(user.cell_number);
        setOpen(true);
        if (handleGroupsEdit(user.groups, "PAMP Manager")) {
            setValue("PAMP Manager");
            setPampManager(true);
            setTeamMember(false);
            setMaintenance(false);
            setViewOnly(false);
        }
        if (handleGroupsEdit(user.groups, "Team Member")) {
            setValue("Team Member");
            setPampManager(false);
            setTeamMember(true);
            setMaintenance(false);
            setViewOnly(false);
        }
        if (handleGroupsEdit(user.groups, "Maintenance")) {
            setValue("Maintenance");
            setPampManager(false);
            setTeamMember(false);
            setMaintenance(true);
            setViewOnly(false);
        }
        if (handleGroupsEdit(user.groups, "View Only")) {
            setValue("View Only");
            setPampManager(false);
            setTeamMember(false);
            setMaintenance(false);
            setViewOnly(true);
        }
    };
    const handleChange = (event) => {
        if (event.target.value === "PAMP Manager") {
            setValue("PAMP Manager");
            setPampManager(true);
            setTeamMember(false);
            setMaintenance(false);
            setViewOnly(false);
        }
        if (event.target.value === "Team Member") {
            setValue("Team Member");
            setPampManager(false);
            setTeamMember(true);
            setMaintenance(false);
            setViewOnly(false);
        }
        if (event.target.value === "Maintenance") {
            setValue("Maintenance");
            setPampManager(false);
            setTeamMember(false);
            setMaintenance(true);
            setViewOnly(false);
        }
        if (event.target.value === "View Only") {
            setValue("View Only");
            setPampManager(false);
            setTeamMember(false);
            setMaintenance(false);
            setViewOnly(true);
        }
    };
    const handleOnSubmit = (event) => {
        event.preventDefault();
        const body = {
            id: user.id,
            first_name: first_name,
            last_name: last_name,
            cell_number: cell_number,

            maintenance: maintenance,

            team_member: team_member,

            pamp_manager: pamp_manager,

            view_only: view_only,

            compliance_officer: compliance_officer,

            pr_eng: preng,
            internal_external: internal_external,
        };
        editUser({ body: body });
    };

    return (
        <>
            <EditIcon onClick={() => handleClick()} sx={{ color: "#13cbc5" }} />

            <Dialog
                BackdropProps={{ invisible: true }}
                PaperProps={{
                    style: {
                        backgroundColor: "#000",
                        boxShadow: "none",
                        borderWidth: 2,
                        borderRadius: 10,
                        borderColor: "#fff",
                        borderStyle: "solid",
                        backgroundColor: "#101010",
                        color: "#fff",
                    },
                }}
                style={theme.modalStyle.root}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <p style={{ color: "#fff" }}>Edit User</p>
                    <ClearIcon
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: "#fff",
                            "&:hover": {
                                color: "red",
                                cursor: "pointer",
                            },
                        }}
                        onClick={handleClose}
                    />
                </DialogTitle>
                <form onSubmit={(e) => handleOnSubmit(e)}>
                    <DialogContent>
                        <TextField
                            onChange={(e) => setfirst_name(e.target.value)}
                            margin="normal"
                            required
                            fullWidth
                            className="inputRounded"
                            id="first_name"
                            label="User name"
                            name="first_name"
                            autoComplete="first_name"
                            value={first_name || ""}
                        />

                        <TextField
                            onChange={(e) => setlast_name(e.target.value)}
                            margin="normal"
                            required
                            fullWidth
                            className="inputRounded"
                            id="last_name"
                            label="User Last name"
                            name="last_name"
                            autoComplete="last_name"
                            value={last_name || ""}
                        />

                        <TextField
                            onChange={(e) => setCell_number(e.target.value)}
                            margin="normal"
                            required
                            fullWidth
                            className="inputRounded"
                            id="cell_number"
                            label="Cell Number"
                            name="cell_number"
                            autoComplete="cell_number"
                            value={cell_number || ""}
                        />
                        <FormControl
                            fullWidth
                            sx={{ mt: 2 }}
                            className="inputRounded"
                        >
                            <InputLabel id="outlined-basic">
                                Internal/External
                            </InputLabel>

                            <Select
                                labelId="type-select-label"
                                id="type-select"
                                value={internal_external}
                                label="Internal/External"
                                onChange={(e) =>
                                    setInternalExternal(e.target.value)
                                }

                            >
                                {internal_external_choices.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Grid container alignItems="center">
                            <Typography
                                style={{ color: "#fff" }}
                                variant="h6"
                                sx={{ mt: "5px", mb: "5px" }}
                            >
                                User Group Selection
                            </Typography>
                            <GroupInfo />
                        </Grid>
                        <Typography
                            style={{ color: "#fff" }}
                            variant="h7"
                            sx={{ mt: "5px", mb: "5px" }}
                        >
                            Main Authorisation *
                        </Typography>
                        <RadioGroup
                            sx={{ mb: 2 }}
                            aria-label="main-group-choices"
                            name="main-group-choices"
                            value={value}
                            onChange={(e) => handleChange(e)}
                        >
                            <FormControlLabel
                                value="PAMP Manager"
                                control={
                                    <Radio
                                        required
                                        color="primary"
                                        checked={pamp_manager}
                                        style={{
                                            color: "#13cbc5",
                                        }}
                                    />
                                }
                                label="PAMP Manager"
                            />
                            <FormControlLabel
                                value="Team Member"
                                checked={team_member}
                                control={
                                    <Radio
                                        required
                                        style={{
                                            color: "#13cbc5",
                                        }}
                                    />
                                }
                                label="Team Member"
                            />
                            <FormControlLabel
                                value="Maintenance"
                                control={
                                    <Radio
                                        required
                                        checked={maintenance}
                                        style={{
                                            color: "#13cbc5",
                                        }}
                                    />
                                }
                                label="Maintenance"
                            />
                            <FormControlLabel
                                value="View Only"
                                control={
                                    <Radio
                                        required
                                        checked={view_only}
                                        style={{
                                            color: "#13cbc5",
                                        }}
                                    />
                                }
                                label="View Only"
                            />
                        </RadioGroup>

                        <Typography
                            style={{ color: "#fff" }}
                            variant="h7"
                            sx={{ mt: "5px", mb: "5px" }}
                        >
                            Auxilliary Authorisations
                        </Typography>
                        <Typography style={{ color: "#fff", marginTop:3 }}>
                            Professional Engineer
                            <Checkbox
                                checked={preng}
                                onChange={(e) => setpreng(e.target.checked)}
                                inputProps={{ "aria-label": "controlled" }}
                                style={{
                                    color: "#13cbc5",
                                }}
                            />
                        </Typography>
                        <Typography style={{ color: "#fff" }}>
                            Compliance Officer
                            <Checkbox
                                checked={compliance_officer}
                                onChange={(e) =>
                                    setcompliance_officer(e.target.checked)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                                style={{
                                    color: "#13cbc5",
                                }}
                            />
                        </Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="submit"
                            variant="outlined"
                            sx={theme.mainButton.root}
                            align="middle"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
